import React, { useContext, useEffect, useRef, useState } from 'react';
import DataContext from '../../config/dataContext';
import { motion } from "framer-motion/dist/framer-motion";
import { Helmet } from "react-helmet";
import Work from '../../components/Work';
import CustomFont from '../../components/CustomFont';
import ContainerTitle from '../../components/ContainerTitle';
import Footer from '../../components/Footer';

const Shows = () => {
  const data = useContext(DataContext);
  const scrollRef = useRef(null);
  const [showsToDisplay, setShowsToDisplay] = useState([])

  useEffect(() => {
    // data.handleScrollSnap(scrollRef.current);
    data.setIsBackgroundAnimated(true);
    data.setIsMbmDifference(false);
  }, []);

  useEffect(() => {
    if (data.isAllDataLoad === true) {
      data.scrollSnapToTop(scrollRef.current)
      setShowsToDisplay(data.shows)
    }
  }, [data.isAllDataLoad])
  
  return (
    <div className="wrapper__works">
      <Helmet>
        <title>{`${data.artistName} — Shows`}</title>
      </Helmet>
      <div className="container__works">
        <ContainerTitle
          title={
            data.defaultLanguage.shortcut === "eng"
            ? "SHOWS"
            : "AUSTELLUNGEN"
          }
          subNavLanguages={data.languages}
          subNavLinks={false}
          subNavLinksWorks={false}
        />
        <div
          className="container__content sst--YMAND"
          ref={scrollRef}
          id="scroll--INSIDE"
          style={{height: `${data.maxHeight}px`}}
        >
          <div className="wrapper__works">
            <div className="container__works" id="scroll--P">
              {showsToDisplay && showsToDisplay
                .sort((a, b) => a.title.localeCompare(b.title))
                .sort((a, b) => b.year - a.year)
                .map((work, i) => 
                <Work work={work} key={i} link="shows" />
              )}
              <Footer />
            </div>
          </div>
          <motion.div
            className={"wrapper__btn--scroll"}
            animate={{
              y: data.isFooterVisible ? "4rem" : 0
            }}
            transition={{
              ease: "easeInOut",
              default: { duration: 0.3 },
            }}
          >
            <CustomFont
              tag={"p"}
              className={"small__font c--GREY"}
              content={"↓"}
              onClick={data.handleArrowScroll}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Shows;