import React from 'react';
import CustomFont from '../CustomFont';
import './workNoImgs.scss';

const WorkNoImgs = () => {

  return (
    <div className="wrapper__no--imgs">
      <div className="container__no--imgs">
        <CustomFont
          tag={"p"}
          className={"small__font"}
          content={"No images."}
        />
      </div>
    </div>
  );
};

export default WorkNoImgs;
