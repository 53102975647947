import React, { useContext } from 'react';
import CustomFont from "../CustomFont";
import DataContext from '../../config/dataContext';
import ExternalLink from '../ExternalLink';
import ReactMarkdown from "react-markdown";
import NoImgs from "../../components/NoImgs";
import Slider from "../Slider";
import "./work.scss";

const Publication = ({ publication }) => {
  const data = useContext(DataContext);

  return (
    <section
    className="section__publication sst--START"
    style={{height: `${data.maxHeight}px`}}
    >
      <div className="medias--part">
        {data.isAllDataLoad && 
          publication.medias.length > 0
          ? <Slider data={publication} isZoomable={data.maxWidth >= 800 ? true : false}/>
          : <NoImgs />
        }
      </div>
      <div className="informations--part">
        <div className="top--part">
          <div className="title">
            <CustomFont
              tag={"h2"}
              className={"medium__font"}
              content={
                data.defaultLanguage.shortcut === "eng"
                  ? publication.name_eng
                  : publication.name_ger
              }
            />
          </div>
          <ReactMarkdown className="description small__font md--RULES" linkTarget="_blank">
            {data.defaultLanguage.shortcut === "eng"
              ? publication.description_eng
              : publication.description_ger}
          </ReactMarkdown>
        </div>
        {publication.link ? (
          <div className="cta--part">
            <ExternalLink
              href={publication.link}
              className={"small__font c--GREY"}
              content={
                data.defaultLanguage.shortcut === "eng"
                  ? "Available here"
                  : "Hier verfügbar"
              }
            />
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default Publication;
