import React, { useContext } from "react";
import ExternalLink from "../ExternalLink";
import DataContext from "../../config/dataContext";
import "./contactLinks.scss";

const ContactLinks = ({ first }) => {
  const data = useContext(DataContext);

  return (
    <ul className={first ? "contact--items t--CAPS first--el" : "contact--items t--CAPS"}>
      <li className="item">
        <ExternalLink
          href={`mailto:${data.informations.mail}`}
          className={"small__font c--GREY"}
          content={"Mail"}
        />
      </li>
      <li className="item">
        <ExternalLink
          href={data.informations.instagram}
          className={"small__font c--GREY"}
          content={"Instagram"}
        />
      </li>
      <li className="item">
        <ExternalLink
          href={data.informations.facebook}
          className={"small__font c--GREY"}
          content={"Facebook"}
        />
      </li>
    </ul>
  );
};

export default ContactLinks;
