import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import CustomFont from "../CustomFont";
import DataContext from "../../config/dataContext";
import ExternalLink from "../ExternalLink";
import ReactMarkdown from "react-markdown";
import Slider from "../Slider";
import WorkNoImgs from "../WorkNoImgs";
import "./work.scss";

const Work = ({ work, link }) => {
  const isWork = work.hasOwnProperty("works") ? false : true;

  const [isCopying, setIsCopying] = useState(false);
  const [isInformationsActive, setIsInformationsActive] = useState(false);
  const data = useContext(DataContext);

  const formatMaterials = (materials) => {
    return materials.charAt(0).toUpperCase() + materials.slice(1);
  };

  // ! NEW 27.12.2023
  const handleCopyLink = (id) => {
    if (isWork === true) {
      const url = `${window.location.origin}/works/${work.id}`;
      navigator.clipboard.writeText(url);
    } else {
      const currentURL = window.location.href;
      navigator.clipboard.writeText(currentURL);
    }
    setIsCopying(true);
    setTimeout(function () {
      setIsCopying(false);
    }, 1500);
  };

  // ! OLD
  // const handleCopyLink = (id) => {
  //   // navigator.clipboard.writeText(`https://${window.location.host}/${link}/${id}`);
  //   const currentURL = window.location.href; // Update 02/03/2023
  //   navigator.clipboard.writeText(currentURL); // Update 02/03/2023
  //   setIsCopying(true)
  //   setTimeout(function() {
  //     setIsCopying(false)
  //   }, 1500)
  // };

  // console.log(window.location.href)
  // console.log(`https://${window.location.host}/${link}/${work.id}`)

  return (
    <section
      className="wrapper__work sst--START"
      style={{ height: `${data.maxHeight}px` }}
    >
      <div className="container__work">
        {work.medias.length >= 1 ? (
          <Slider
            data={work}
            isZoomable={true}
            isOpacity={isInformationsActive ? true : false}
          />
        ) : (
          <WorkNoImgs />
        )}
        <div className="container__informations--work">
          {isInformationsActive && (
            <CustomFont
              tag={"p"}
              className={"small__font"}
              content={"Sébastien de Ganay"}
            />
          )}
          {work.full_title ? (
            <CustomFont
              tag={"p"}
              className={"small__font"}
              content={work.full_title}
            />
          ) : (
            <CustomFont
              tag={"p"}
              className={"small__font"}
              content={work.title}
            />
          )}
          {/* // NEW !! // */}
          {work.secondary_title && (
            <CustomFont
              tag={"p"}
              className={"small__font"}
              content={work.secondary_title}
            />
          )}
          {work.collaboration && (
            <CustomFont
              tag={"p"}
              className={"small__font"}
              content={work.collaboration}
            />
          )}
          {work.commission && (
            <CustomFont
              tag={"p"}
              className={"small__font"}
              content={work.commission}
            />
          )}
          {/* // NEW !! // */}
          {isInformationsActive && work.edition && (
            <CustomFont
              tag={"p"}
              className={"small__font"}
              content={formatMaterials(work.edition)}
            />
          )}
          {isInformationsActive &&
            work.location &&
            (work.link ? (
              <ExternalLink
                href={work.link}
                className={"small__font c--GREY-invert"}
                content={work.location}
              />
            ) : (
              <CustomFont
                tag={"p"}
                className={"small__font"}
                content={work.location}
              />
            ))}
          {isInformationsActive && (
            <CustomFont
              tag={"p"}
              className={
                (work.works && work.credits != undefined) ||
                work.materials != undefined
                  ? "small__font padding--YEAR"
                  : "small__font"
              }
              content={`${work.year}`}
            />
          )}
          {isInformationsActive && work.materials && (
            <CustomFont
              tag={"p"}
              className={"small__font"}
              content={formatMaterials(work.materials)}
            />
          )}
          {isInformationsActive && work.dimensions && (
            <CustomFont
              tag={"p"}
              className={"small__font"}
              content={work.dimensions}
            />
          )}
          {isInformationsActive && work.description && (
            <ReactMarkdown className="small__font md--DESC" linkTarget="_blank">
              {work.description}
            </ReactMarkdown>
          )}
          {isInformationsActive && work.credits && (
            <CustomFont
              tag={"p"}
              className={"small__font"}
              content={`Photography: ${work.credits}`}
            />
          )}
          <div
            className={
              isInformationsActive
                ? "container__cta is--ACTIVE"
                : "container__cta"
            }
          >
            <p
              className={"small__font external-LINK c--GREY CTA"}
              onClick={() => setIsInformationsActive(!isInformationsActive)}
            >
              {isInformationsActive
                ? data.defaultLanguage.shortcut === "eng"
                  ? "Less details"
                  : "Details ausblenden"
                : data.defaultLanguage.shortcut === "eng"
                ? "More details"
                : "Details"}
            </p>
            {work.hasOwnProperty("works") === true &&
              work.works.length >= 1 && (
                <Link
                  className={"small__font external-LINK c--GREY CTA"}
                  to={`/shows/${work.id}/details`}
                >
                  {data.defaultLanguage.shortcut === "eng"
                    ? "Works list"
                    : "Werkliste"}
                </Link>
              )}
            <p
              className={"small__font external-LINK c--GREY CTA"}
              onClick={() => handleCopyLink(work.id)}
            >
              {isCopying
                ? data.defaultLanguage.shortcut === "eng"
                  ? "Link copied to clipboard"
                  : "Link kopiert"
                : data.defaultLanguage.shortcut === "eng"
                ? "Copy link"
                : "Link kopieren"}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Work;
