import React, { useContext } from 'react';
import DataContext from '../../config/dataContext';
import { motion } from 'framer-motion/dist/framer-motion';
import Logo from '../../assets/logo';
import CustomFont from '../CustomFont';
import './header.scss';

const Header = () => {
  const data = useContext(DataContext);

  const handleMenu = () => {
    data.handleMenu()
    if (data.isFilterWorks === true) {
      setTimeout(function() {
        data.handleIsFilterWorks()
      }, 500)
    }
  }

  return (
    <header className="wrapper__header">
      <div className="container__header">
        <Logo />
        <div className="container__toggle--menu">
          <motion.button
            onClick={() => handleMenu()}
            className="toggle--menu"
            initial={{
              x: '10rem'
            }}
            animate={{
              x: data.isAllDataLoad ? 0 : '10rem'
            }}
            transition={{
              ease: "easeInOut",
              default: { duration: 0.75 },
              delay: 0.75
            }}
          >
            <CustomFont
              tag="p"
              className="small__font t--CAPS"
              content={data.isMenuActive ? 
                "exit"
                :
                data.defaultLanguage.shortcut === "eng"
                ? "menu"
                : "menü"
              }
            />
          </motion.button>
        </div>
      </div>
    </header>
  );
};

export default Header;