import React, { useContext } from "react";
import DataContext from "../../config/dataContext";
import CustomFont from "../CustomFont";
import ContactLinks from "../ContactLinks";
import "./footer.scss";

const Footer = () => {
  const data = useContext(DataContext);
  const currentYear = new Date().getFullYear();

  return (
    <footer className="wrapper__footer sst--START" id="anim--FOOTER" style={{height: `${data.maxHeight}px`}}>
      <div className="container__footer">
        <div className="copyright">
          <CustomFont
            tag="p"
            className="small__font"
            content={
              `${
                data.defaultLanguage.shortcut === "eng"
                  ? "All rights reserved"
                  : "Alle rechte vorbehalten"
              }`
            }
          />
          <CustomFont
            tag="p"
            className="small__font"
            content={`© Studio Sébastien de Ganay ${currentYear}`}
          />
        </div>
        <ContactLinks />
      </div>
    </footer>
  );
};

export default Footer;
