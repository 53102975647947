import React, { useContext } from "react";
import { Link } from "react-router-dom";
import DataContext from "../../config/dataContext";
import CustomFont from "../CustomFont";
import ExternalLink from "../ExternalLink";
import './biographyElement.scss';

const BiographyElement = ({ ctx }) => {
  const data = useContext(DataContext);

  return (
    <ul className="container__biography--element">
      {[...new Set(ctx.sort((a, b) => b.year - a.year).map(ex => ex.year))]
        .map((currentYear, i) => 
          <li
            className="container__each--year"
            key={i + 1}
          >
            <div className="container__year">
              {currentYear && (
                <CustomFont
                  tag={"p"}
                  className={"small__font"}
                  content={currentYear}
                />
              )}
            </div>
            <div className="container__content">
              {ctx.filter(el => el.year == currentYear).map((filteredEl, i) =>
                <div className="content" key={i + 1} >
                  {filteredEl.title_eng &&
                    <div className="title">
                      {filteredEl.title_eng &&
                        <CustomFont
                          tag={"p"}
                          className={"small__font t--ITAL"}
                          content={
                            data.defaultLanguage.shortcut === "eng"
                            ? `${filteredEl.title_eng}`
                            : `${filteredEl.title_ger}`
                          }
                        />
                      }
                    </div>
                  }
                  <div className="secondary">
                    {filteredEl.location_eng && (
                      <CustomFont
                        className={"small__font"}
                        content={
                          data.defaultLanguage.shortcut === "eng"
                          ? filteredEl.title_eng != null && filteredEl.title_eng != "" ? filteredEl.location_eng : `${filteredEl.location_eng}`
                          : filteredEl.title_ger != null && filteredEl.title_ger != "" ? filteredEl.location_ger : `${filteredEl.location_ger}`
                        }
                        tag={"p"}
                      />
                    )}
                    {filteredEl.description_eng && (
                      <CustomFont
                        className={"small__font"}
                        content={
                          data.defaultLanguage.shortcut === "eng"
                          ? filteredEl.description_eng
                          : filteredEl.description_ger
                        }
                        tag={"p"}
                      />
                    )}
                    {filteredEl.show != null || filteredEl.link != null ?
                      <div className="cta">
                        {filteredEl.show && 
                          <Link
                            to={`/shows/${filteredEl.show.id}`}
                            className={"small__font external-LINK c--GREY"}
                          >
                            {data.defaultLanguage.shortcut === "eng"
                            ? "Installation views"
                            : "Ausstellungsansichten"
                            }
                          </Link>
                        }
                        {filteredEl.link && 
                          <ExternalLink
                            href={filteredEl.link}
                            className={"small__font c--GREY"}
                            content={"Website"}
                          />
                        }
                      </div> : null
                    }
                  </div>
                </div>
              )}
            </div>
          </li>
        )
      }
    </ul>
  );
};

export default BiographyElement;
