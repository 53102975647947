import React, { useContext, useEffect, useRef } from "react";
import ContainerTitle from "../../components/ContainerTitle";
import DataContext from "../../config/dataContext";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Publication from "../../components/Publication";
import "./publications.scss";

const Publications = () => {
  const data = useContext(DataContext);
  const scrollRef = useRef(null);

  useEffect(() => {
    data.handleScrollSnap(scrollRef.current);
    data.setIsBackgroundAnimated(true);
    data.setIsMbmDifference(false);
  }, []);

  return (
    <div className="wrapper__publications">
      <Helmet>
        <title>{`${data.artistName} — Publications`}</title>
      </Helmet>
      <div className="container__publications">
        <ContainerTitle
          title={
            data.defaultLanguage.shortcut === "eng"
            ? "PUBLICATIONS"
            : "BIBLIOGRAFIE"
          }
          subNavLanguages={data.languages}
          subNavLinks={false}
        />
        <div
          className="container__content sst--YMAND"
          ref={scrollRef}
          id="scroll--INSIDE"
          style={{height: `${data.maxHeight}px`}}
        >
          {data.publications
            .sort((a, b) => b.year - a.year)
            .map((publication, i) => (
              <Publication publication={publication} key={i}/>
            ))}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Publications;
