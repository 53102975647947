import React, { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import DataContext from '../../config/dataContext';
import { Helmet } from "react-helmet";
import Work from '../../components/Work';
import ContainerTitle from '../../components/ContainerTitle';
import Footer from '../../components/Footer';

const UniqShow = () => {
  const data = useContext(DataContext);
  const scrollRef = useRef(null);
  const { showID } = useParams();
  const show = data.shows.find(show => show.id == showID);

  useEffect(() => {
    data.handleScrollSnap(scrollRef.current);
    data.setIsBackgroundAnimated(true);
    data.setIsMbmDifference(false);
  }, []);

  return (
    <div className="wrapper__works">
      <Helmet>
        <title>{`${data.artistName} — ${show?.title}`}</title>
      </Helmet>
      <div className="container__works">
        <ContainerTitle
          title={"SHOWS"}
          subNavLanguages={false}
          subNavLinks={false}
          subNavLinksWorks={false}
        />
        <div
          className="container__content sst--YMAND"
          ref={scrollRef}
          id="scroll--INSIDE"
          style={{height: `${data.maxHeight}px`}}
        >
          <div className="wrapper__works">
            <div className="container__works">
              {data.isAllDataLoad && <Work work={show} link="shows"/>}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniqShow;