import React, { useContext, useRef, useEffect, useState } from "react";
import DataContext from "../../config/dataContext";
import ContainerTitle from "../../components/ContainerTitle";
import CustomFont from "../../components/CustomFont";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import BiographyElement from "../../components/BiographyElement";
// import { motion } from "framer-motion/dist/framer-motion";
import './biography.scss';
import BioNavLink from "../../components/BioNavLink";

// EINZELAUSSTELLUNGEN
// GRUPPENAUSSTELLUNGEN
// KUNSTMESSEN
// AUSZEICHNUNGEN

const Biography = () => {
  const data = useContext(DataContext);
  const scrollRef = useRef(null);
  const [navLinks, setNavLinks] = useState([
    {name_ger: "EINZELAUSSTELLUNGEN", name_eng: "solo exhibitions", isActive: false, slug: "solo_exhibitions"},
    {name_ger: "GRUPPENAUSSTELLUNGEN", name_eng: "group exhibitions", isActive: false, slug: "group_exhibitions"},
    {name_ger: "KUNSTMESSEN", name_eng: "art fairs", isActive: false, slug: "art_fairs"},
    {name_ger: "AUSZEICHNUNGEN", name_eng: "awards", isActive: false, slug: "awards"},
  ]);
  const width = window.innerWidth;

  useEffect(() => {
    data.handleScrollSnap(scrollRef.current);
    data.setIsBackgroundAnimated(true);
    data.setIsMenuClosing(true);
    data.setIsMbmDifference(false);
  }, []);

  const handleNav = (link) => {
    let updatedLinks = navLinks.map((el) => {
      if (el.name_eng === link.name_eng) {
        return {...el, isActive: true}
      }
      return {...el, isActive: false}
    })
    setNavLinks(updatedLinks)
  }

  return (
    <div className="wrapper__biography X">
      <Helmet>
        <title>{`${data.artistName} — Biography`}</title>
      </Helmet>
      <div className="container__biography">
        <ContainerTitle
          title={
            data.defaultLanguage.shortcut === "eng"
            ? "BIOGRAPHY"
            : "BIOGRAFIE"
          }
          subNavLanguages={data.languages}
          subNavLinksBibliography={false}
        />
        <div
          className="container__content sst--YMAND"
          ref={scrollRef}
          id="scroll--INSIDE"
          style={{height: `${data.maxHeight}px`}}
        >
          <div
            className="wrapper__biography--element sst--START"
            style={{height: `${data.maxHeight}px`}}
            id="scroll--INSIDE--bibliography"
          >
            <div
              className="container--left"
              style={{height: data.maxWidth >= 800 ? `${data.maxHeight}px` : 'auto'}}
            >
              <div className="container--top">
                <BioNavLink navLinks={navLinks} handleNav={handleNav} />
                <ul className="container__steps">
                  {data.isAllDataLoad === true && data.informations.media &&
                    <img
                      src={data.informations.media.url}
                      alt="Sébastien de Ganay profile picture"
                      className="profile"
                    />
                  }
                  {data.isAllDataLoad === true && data.informations.Steps.map((step, i) =>
                    <li className="step" key={i}>
                      <CustomFont
                        tag={"p"}
                        className={"small__font"}
                        content={step.year}
                      />
                      <ReactMarkdown
                        className="small__font md--RULES"
                        linkTarget="_blank"
                        children="string"
                      >
                        {data.defaultLanguage.shortcut === "eng"
                          ? step.description_eng
                          : step.description_ger}
                      </ReactMarkdown>
                    </li>
                  )}
                </ul>
              </div>
              <div className="container--bottom">
                {data.isAllDataLoad && data.informations.cv_eng?.url && data.informations.cv_ger?.url &&
                  <a
                    href={
                      data.defaultLanguage.shortcut === "eng"
                      ? data.informations.cv_eng.url
                      : data.informations.cv_ger.url
                    }
                    className="small__font external-LINK c--GREY"
                    target="_blank"
                  >
                    {data.defaultLanguage.shortcut === "eng"
                      ? "Download full biography (PDF)"
                      : "Vollständige Biografie herunterladen (PDF)"
                    }
                  </a>
                }
              </div>
            </div>
            <div className="container--right">
              <div className="container--element" id={"solo_exhibitions"} style={{minHeight: `${data.maxHeight - 216}px`}}>
                <CustomFont
                  tag={"h2"}
                  className={"small__font t--CAPS"}
                  content={data.defaultLanguage.shortcut === "eng"
                  ? "solo exhibitions"
                  : "EINZELAUSSTELLUNGEN"
                  }
                />
                <BioNavLink navLinks={navLinks} handleNav={handleNav} />
                <BiographyElement ctx={data.soloExhibitions}/>
              </div>
              <div className="container--element" id={"group_exhibitions"} style={{minHeight: `${data.maxHeight - 216}px`}}>
                <CustomFont
                  tag={"h2"}
                  className={"small__font t--CAPS"}
                  content={data.defaultLanguage.shortcut === "eng"
                  ? "group exhibitions"
                  : "GRUPPENAUSSTELLUNGEN"
                  }
                />
                <BioNavLink navLinks={navLinks} handleNav={handleNav} />
                <BiographyElement ctx={data.groupExhibitions}/>
              </div>
              <div className="container--element" id={"art_fairs"} style={{minHeight: `${data.maxHeight - 216}px`}}>
                <CustomFont
                  tag={"h2"}
                  className={"small__font t--CAPS"}
                  content={data.defaultLanguage.shortcut === "eng"
                  ? "art fairs"
                  : "KUNSTMESSEN"
                  }
                />
                <BioNavLink navLinks={navLinks} handleNav={handleNav} />
                <BiographyElement ctx={data.artFairs}/>
              </div>
              <div
                className="container--element"
                id={"awards"}
                style={{height: data.maxWidth >= 800 ? `${data.maxHeight - 16}px` : `${data.maxHeight - 227}px`}}
              >
                <CustomFont
                  tag={"h2"}
                  className={"small__font t--CAPS"}
                  content={data.defaultLanguage.shortcut === "eng"
                  ? "awards"
                  : "AUSZEICHNUNGEN"
                  }
                />
                <BioNavLink navLinks={navLinks} handleNav={handleNav} />
                <BiographyElement ctx={data.awards}/>
              </div>
              <div className="container--bottom">
                {data.isAllDataLoad && data.informations.cv_eng?.url && data.informations.cv_ger?.url &&
                  <a
                    href={
                      data.defaultLanguage.shortcut === "eng"
                      ? data.informations.cv_eng.url
                      : data.informations.cv_ger.url
                    }
                    className="small__font external-LINK c--GREY"
                    target="_blank"
                  >
                    {data.defaultLanguage.shortcut === "eng"
                      ? "Download full biography (PDF)"
                      : "Vollständige Biografie herunterladen (PDF)"
                    }
                  </a>
                }
              </div>
            </div>
          </div>
          {/* <motion.div
            className="top--BTN"
            animate={{
              y: data.isFooterVisible ? "4rem" : 0
            }}
            transition={{
              ease: "easeInOut",
              default: { duration: 0.3 },
            }}
          >
            <CustomFont
              tag={"p"}
              className={"small__font external-LINK c--GREY"}
              content={"Go to top"}
              onClick={data.handleScrollToTop}
            />
          </motion.div> */}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Biography;
