import React, { useContext } from 'react';
import CustomFont from '../CustomFont';
import DataContext from "../../config/dataContext";
import { v4 as uuidv4 } from 'uuid';
import './subNavLanguages.scss'

const SubNavLanguages = ({ subNavLanguages }) => {
  const data = useContext(DataContext);

  const handleDefaultLanguage = (language) => {
    data.setDefaultLanguage(language)
  }

  return (
    <div className="container__subNavLanguages">
      <ul className="subNavLanguages--items">
        {subNavLanguages.map((language) => 
          <li
            key={uuidv4()}
            onClick={() => handleDefaultLanguage(language)}
            className={language.name === data.defaultLanguage.name ? "item active" : "item"}
          >
            <CustomFont
              tag="p"
              className="small__font t--CAPS"
              content={language.name}
            />
          </li>
        )}
      </ul>
    </div>
  );
};

export default SubNavLanguages;