import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import DataContext from '../../config/dataContext';
import SubNavLanguages from '../SubNavLanguages';
import { motion } from 'framer-motion/dist/framer-motion';
import './menu.scss';

const Menu = () => {
  const data = useContext(DataContext);
  const links = [
    // {title: "home", slug: "/"},
    {title_ger: "WERKE", title_eng: "works", slug: "/works"},
    {title_ger: "AUSTELLUNGEN", title_eng: "shows", slug: "/shows"},
    {title_ger: "BIOGRAFIE", title_eng: "biography", slug: "/biography"},
    {title_ger: "BIBLIOGRAFIE", title_eng: "publications", slug: "/publications"},
    {title_ger: "SUCHE", title_eng: "search", slug: "/search"},
    {title_ger: "KONTAKT", title_eng: "contact", slug: "/contact"},
  ]

  const container = {
    hidden: {
      transition: {
        delayChildren: 0,
        staggerChildren: 0.15,
        staggerDirection: data.isMenuActive ? 1 : -1,
      }
    },
    visible: {
      transition: {
        delayChildren: 0.25,
        staggerChildren: 0.15,
        staggerDirection: data.isMenuActive ? 1 : -1
      }
    }
  };
  
  const item = {
    hidden: {
      y: data.isMenuActive ? '3rem' : '-3rem',
      opacity: 0,
      transition: {
        opacity: {
          duration: 0.25,
        }
      }
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeOut",
        opacity: {
          duration: 0.25,
        }
      }
    }
  };

  return (
    <div className={data.isMenuActive ? "wrapper__menu" : "wrapper__menu hidden"} style={{height: `${data.maxHeight}px`}}>
      <div className="container__menu t--CAPS blur">
        <div className="center__menu">
          <motion.ul
            className="menu--items"
            variants={container}
            initial="hidden"
            animate={data.isMenuActive ? "visible" : "hidden"}
          >
            {links.map((link, i) =>
              <motion.li
                className="item"
                variants={item}
                key={i}
              >
                <Link
                  to={link.slug}
                  onClick={data.handleMenu}
                >
                  <p className="big__font">
                    {data.defaultLanguage.shortcut === "eng"
                      ? `${link.title_eng}`
                      : `${link.title_ger}`}
                  </p>
                </Link>
              </motion.li>
            )}
          </motion.ul>
        </div>
        <motion.div
          className="bot__menu"
          initial={{
            translateY: '4rem'
          }}
          animate={{
            translateY: data.isMenuActive ? 0 : '6rem'
          }}
          transition={{
            transition: {
              duration: data.isMenuActive ? 0.3 : 0.15,
              ease: "easeOut"
            },
            default: { duration: 0.6 },
            delay: data.isMenuActive ? 1 : 0
          }}
        >
          <div className="container__links">
            {data.news.length >= 1 &&
              <div className="item">
                <Link
                  to="/news"
                  onClick={data.handleMenu}
                >
                  <p className="small__font">news</p>
                </Link>
              </div>
            }
            <div className="item">
              <Link
                to="/imprint"
                onClick={data.handleMenu}
              >
                <p className="small__font">
                  {data.defaultLanguage.shortcut === "eng"
                  ? "imprint"
                  : "IMPRESSUM"}
                </p>
              </Link>
            </div>
          </div>
          <SubNavLanguages subNavLanguages={data.languages}/>
        </motion.div>
      </div>
    </div>
  );
};

export default Menu;