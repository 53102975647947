import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ContainerTitle from "../../components/ContainerTitle";
import { Helmet } from "react-helmet";
import DataContext from "../../config/dataContext";
import ReactMarkdown from "react-markdown";
import CustomFont from "../../components/CustomFont";
import ExternalLink from "../../components/ExternalLink";
import SubNavLinksWorks from "../../components/SubNavLinksWorks";
import Footer from "../../components/Footer";
import "./home.scss";

const Home = () => {
  const data = useContext(DataContext);
  const history = useHistory();
  const scrollRef = useRef(null);
  const [lastNews, setLastNews] = useState([]);
  const [randomWork, setRandomWork] = useState({});
  const [randomWorkSlug, setRandomWorkSlug] = useState("");

  const sortedPNG = (string) => {
    return parseInt(string.split('.')[0].split('_').slice(-1), 10)
  }

  useEffect(() => {
    data.handleScrollSnap(scrollRef.current);
    data.setIsBackgroundAnimated(true);
    data.setIsMbmDifference(false);
  }, []);

  useEffect(() => {
    if (data.isAllDataLoad === true) {
      handleRandomWork();
      setLastNews(data.news.sort((a, b) => a.order - b.order)[0])
    }
  }, [data.isAllDataLoad]);

  const handleRandomWork = () => {
    let randomNb = Math.floor(Math.random() * 11)
    if (randomNb % 2 === 0) {
      setRandomWorkSlug('works')
      setRandomWork(data.informations.works[Math.floor(Math.random()*data.informations.works.length)])
    } else {
      setRandomWorkSlug('shows')
      setRandomWork(data.informations.shows[Math.floor(Math.random()*data.informations.shows.length)])
    }
  }

  return (
    <div className="wrapper__works">
      <Helmet>
        <title>{`${data.artistName}`}</title>
      </Helmet>
      <div className="container__works">
        <ContainerTitle
          title={"WORKS"}
          subNavLanguages={false}
          subNavLinks={false}
          subNavLinksWorks={true}
        />
        <SubNavLinksWorks isMobile={true} />
        <div
          className="container__content"
          // className="container__content sst--YMAND"
          // ref={scrollRef}
          // id="scroll--INSIDE"
          style={{
            // height: `${data.maxHeight}px`,
            opacity: data.isUnderLink ? 0.25 : 1
          }}
        >
          <div className="wrapper__works">
            <div 
              className="container__works sst--YMAND"
              id="scroll--INSIDE"
              style={{height: `${data.maxHeight}px`}}
            >
              <section
                className="wrapper__highlights--home sst--START"
                style={{height: `${data.maxHeight}px`}}
              >
                {lastNews &&                
                  <div className="container__last--new">
                    <CustomFont
                      tag={"p"}
                      className={"small__font"}
                      content={
                        data.defaultLanguage.shortcut === "eng"
                          ? "Latest news:"
                          : "Letzte Nachrichten:"
                      }
                    />
                    <div className="title--part">
                      <ReactMarkdown className="small__font">
                        {data.defaultLanguage.shortcut === "eng"
                          ? lastNews.title_eng
                          : lastNews.title_eng}
                      </ReactMarkdown>
                      <CustomFont
                        tag={"p"}
                        className={"small__font t--CAPS"}
                        content={
                          data.defaultLanguage.shortcut === "eng"
                            ? lastNews.type_eng
                            : lastNews.type_ger
                        }
                      />
                    </div>
                    <div className="content--part">
                      <ReactMarkdown className="small__font">
                        {data.defaultLanguage.shortcut === "eng"
                          ? lastNews.content_eng
                          : lastNews.content_ger}
                      </ReactMarkdown>
                    </div>
                    <div className="informations--part">
                      <ReactMarkdown className="small__font p--LINE">
                        {data.defaultLanguage.shortcut === "eng"
                          ? lastNews.informations_eng
                          : lastNews.informations_ger}
                      </ReactMarkdown>
                      {lastNews.link && (
                        <ExternalLink
                          href={lastNews.link}
                          className={"small__font c--GREY"}
                          content={"Website"}
                        />
                      )}
                    </div>
                  </div>
                }
                <div
                  className="container__random--hightlights"
                  style={{height: `${data.maxHeight}px`}}
                >
                  {randomWork.medias &&
                    <div
                      className="random--hightlights"
                      // to={`/${randomWorkSlug}/${randomWork.id}`}
                      onClick={() => history.push(`/${randomWorkSlug}/${randomWork.id}`)}
                    >
                      <img
                        src={randomWork.medias.sort((a, b) => sortedPNG(a.name) - sortedPNG(b.name))[0].url}
                        className="random--hightlights"
                      />
                    </div>
                  }
                  {/* {randomWork.medias &&
                    <Link
                      className="random--hightlights"
                      to={`/${randomWorkSlug}/${randomWork.id}`}
                    >
                      <img
                        src={randomWork.medias.sort((a, b) => sortedPNG(a.name) - sortedPNG(b.name))[0].url}
                        className="random--hightlights"
                      />
                    </Link>
                  } */}
                  <CustomFont
                    tag={"p"}
                    className={lastNews ? "small__font external-LINK c--GREY CTA" : "small__font external-LINK c--GREY CTA no--NEWS"}
                    content={
                      data.defaultLanguage.shortcut === "eng"
                        ? "Shuffle"
                        : "Mischen"
                    }
                    onClick={handleRandomWork}
                  />
                </div>
              </section>              
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
