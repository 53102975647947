import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { motion } from "framer-motion/dist/framer-motion";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import DataContext from "../../config/dataContext";
import Work from "../../components/Work";
import CustomFont from "../../components/CustomFont";
import Publication from "../../components/Publication";
import ContainerTitle from "../../components/ContainerTitle";
import Footer from "../../components/Footer";
import "./search.scss";

const Search = () => {
  const data = useContext(DataContext);
  const history = useHistory();
  const scrollRef = useRef(null);
  const { searchParams } = useParams();
  const [arrSearchParams, setArrSearchParams] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [worksToDisplay, setWorksToDisplay] = useState([]);
  const [showsToDisplay, setShowsToDisplay] = useState([]);
  const [publicationsToDisplay, setPublicationsToDisplay] = useState([]);
  const [resultsNb, setResultsNb] = useState(0);

  useEffect(() => {
    data.handleScrollSnap(scrollRef.current);
    data.setIsBackgroundAnimated(true);
    data.setIsMbmDifference(false);
  }, []);

  useEffect(() => {
    if (
      searchParams != undefined &&
      searchParams.length >= 3 &&
      data.isAllDataLoad === true
    ) {
      setInputValue(searchParams);
      setArrSearchParams(searchParams.split(" "));
    }
  }, [searchParams, data.isAllDataLoad]);

  useEffect(() => {
    if (data.isAllDataLoad === true && arrSearchParams.length >= 1) {
      handleFiltering(
        setWorksToDisplay,
        searchParams.toLowerCase().split(" "),
        data.works
      );
      handleFiltering(
        setShowsToDisplay,
        searchParams.toLowerCase().split(" "),
        data.shows
      );
      handleFiltering(
        setPublicationsToDisplay,
        searchParams.toLowerCase().split(" "),
        data.publications
      );
    }
  }, [arrSearchParams, data.isAllDataLoad]);

  const handleFiltering = (setFunction, params, data) => {
    let filteredData = data.filter((item) =>
      params.every((param) =>
        JSON.stringify(item).toLowerCase().includes(param)
      )
    );
    setFunction(filteredData);
  };

  useEffect(() => {
    setResultsNb(
      worksToDisplay.length +
        showsToDisplay.length +
        publicationsToDisplay.length
    );
  }, [worksToDisplay, showsToDisplay, publicationsToDisplay]);

  useEffect(() => {
    if (resultsNb >= 1 && data.isAllDataLoad === true) {
      // setTimeout(scroll100, 350);
      data.handleArrowScroll()
    }
  }, [resultsNb, data.isAllDataLoad]);

  useEffect(() => {
    if (searchParams === undefined) {
      handleReset();
      setTimeout(data.handleScrollToTop(0), 500);
    }
  }, [searchParams]);

  const handleReset = () => {
    setWorksToDisplay([]);
    setShowsToDisplay([]);
    setPublicationsToDisplay([]);
    setInputValue("");
  };

  const scroll100 = () => {
    document.getElementById("scroll--INSIDE").scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  const handleValue = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearch = (e) => {
    if (e.target.value.length >= 3 && e.keyCode === 13) {
      history.push(`/search=${e.target.value}`);
      setTimeout(handleResultScroll, 50);
    }
  };

  const handleClickSearch = () => {
    if (inputValue.length >= 3) {
      history.push(`/search=${inputValue}`);
    }
    setTimeout(handleResultScroll, 50);
  };

  const handleResultScroll = () => {
    if (resultsNb >= 1) {
      // setTimeout(scroll100, 350);
      data.handleArrowScroll()
    }
  };

  return (
    <div className="wrapper__works">
      <Helmet>
        <title>{`${data.artistName} — Search`}</title>
      </Helmet>
      <div className="container__works">
        <ContainerTitle
          title={
            searchParams != undefined
              ? data.defaultLanguage.shortcut === "eng"
                ? `SEARCH = [${searchParams}]`
                : `SUCHE = [${searchParams}]`
              : data.defaultLanguage.shortcut === "eng"
              ? "SEARCH"
              : "SUCHE"
          }
          subNavLanguages={data.languages}
          subNavLinks={false}
          subNavLinksWorks={false}
        />
        <div
          className="container__content"
          // className="container__content sst--YMAND"
          // ref={scrollRef}
          // id="scroll--INSIDE"
          // style={{height: `${data.maxHeight}px`}}
        >
          <div className="wrapper__works">
            <div
              className="container__works sst--YMAND"
              style={{ height: `${data.maxHeight}px` }}
              id="scroll--INSIDE"
            >
              <section
                className="wrapper__work search sst--START"
                style={{ height: `${data.maxHeight}px` }}
              >
                <div className="container__form">
                  <input
                    type="text"
                    placeholder={
                      data.defaultLanguage.shortcut === "eng"
                        ? "Search"
                        : "Suche"
                    }
                    value={inputValue}
                    className="big__font input--TEXT"
                    onKeyDown={() => handleSearch(event)}
                    onChange={() => handleValue(event)}
                    autoFocus={true}
                    autoComplete="off"
                  />
                  {inputValue.length >= 3 && (
                    <CustomFont
                      className={"small__font t--CAPS external-LINK c--GREY"}
                      content={"search"}
                      tag={"p"}
                      onClick={handleClickSearch}
                    />
                  )}
                  {searchParams != undefined && searchParams.length >= 3 && (
                    <CustomFont
                      tag={"p"}
                      className={"small__font"}
                      content={
                        resultsNb >= 1
                          ? `${resultsNb} ${
                              resultsNb === 1
                                ? data.defaultLanguage.shortcut === "eng"
                                  ? "result."
                                  : "Ergebnis."
                                : data.defaultLanguage.shortcut === "eng"
                                ? "results."
                                : "Ergebnisse."
                            }`
                          : data.defaultLanguage.shortcut === "eng"
                          ? "No result."
                          : "Keine Ergebnisse."
                      }
                    />
                  )}
                </div>
              </section>
              {worksToDisplay &&
                worksToDisplay
                  .sort((a, b) => b.sequ_number - a.sequ_number)
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .sort((a, b) => b.year - a.year)
                  .map((work, i) => <Work work={work} key={i} />)}
              {showsToDisplay &&
                showsToDisplay
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .sort((a, b) => b.year - a.year)
                  .map((show, i) => <Work work={show} key={i} link="shows" />)}
              {publicationsToDisplay &&
                publicationsToDisplay
                  .sort((a, b) => b.year - a.year)
                  .map((publication, i) => (
                    <Publication publication={publication} key={i} />
                  ))}
              <Footer />
              <motion.div
                className={"wrapper__btn--scroll"}
                animate={{
                  y: data.isFooterVisible ? "4rem" : 0,
                }}
                transition={{
                  ease: "easeInOut",
                  default: { duration: 0.3 },
                }}
              >
                <CustomFont
                  tag={"p"}
                  className={"small__font c--GREY"}
                  content={"↓"}
                  onClick={data.handleArrowScroll}
                />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
