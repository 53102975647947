import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../config/dataContext";
import "./animatedBackground.scss";
import BlueImage from "../../assets/backgrounds/blue_blur_background.jpg";
import PinkImage from "../../assets/backgrounds/pink_blur_background.jpg";
import WhiteImage from "../../assets/backgrounds/white_blur_background.jpg";
import YellowImage from "../../assets/backgrounds/yellow_blur_background.jpg";

const AnimatedBackground = () => {
  const data = useContext(DataContext);
  const [currentImage, setCurrentImage] = useState();
  const [classColor, setClassColor] = useState();

  useEffect(() => {
    handleCurrentImage();
  }, []);

  useEffect(() => {
    if (data.isBackgroundAnimated === true) {
      handleCurrentImage();
    }
  }, [data.isBackgroundAnimated]);

  const handleCurrentImage = () => {
    let indexOfImage = Math.floor(Math.random() * 4);
    if (indexOfImage === 0) {
      setCurrentImage(BlueImage);
      setClassColor("blue")
    } else if (indexOfImage === 1) {
      setCurrentImage(PinkImage);
      setClassColor("pink")
    } else if (indexOfImage === 2) {
      setCurrentImage(WhiteImage);
      setClassColor("white")
    } else {
      setCurrentImage(YellowImage)
      setClassColor("yellow")
    };
  }

  return (
    <div className="wrapper__animated--background">
      <div className="container__white--color" style={{height: `${data.maxHeight}px`}}></div>
      <div
        className={
          data.isBackgroundAnimated
            ? `container__anti--blur ${classColor} is--ACTIVE`
            : `container__anit--blur ${classColor}`
        }
        style={{height: `${data.maxHeight}px`}}
      >
      </div>
      <div
        className={
          data.isBackgroundAnimated
            ? "container__animated--background is--ACTIVE"
            : "container__animated--background"
        }
        style={{ backgroundImage: `url(${currentImage})`, height: `${data.maxHeight}px` }}
      >
      </div>
    </div>
  );
};

export default AnimatedBackground;
