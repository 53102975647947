import React from 'react';
import './externalLink.scss';

const ExternalLink = ({ href, className, content }) => {

  return (
    <a 
      href={href}
      className={className + " external-LINK"}
      target="_blank"
    >
      {content}
    </a>
  );
};

export default ExternalLink;
