import React from "react";
import './customFont.scss';

const CustomFont = ({
    tag: Tag,
    className: className,
    content: content,
    onClick: onClick
  }) => {

  return (
    <Tag
      className={className}
      onClick={onClick}
    >
      {content}
    </Tag>
  );
};

export default CustomFont;
