import React, { useContext, useEffect, useRef } from "react";
import ContainerTitle from "../../components/ContainerTitle";
import DataContext from "../../config/dataContext";
import ReactMarkdown from "react-markdown";
import CustomFont from "../../components/CustomFont";
import ExternalLink from "../../components/ExternalLink";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import { motion } from 'framer-motion/dist/framer-motion';
// import { container, item } from "../../assets/animation/list";
// import { v4 as uuidv4 } from "uuid";
import "./news.scss";

const News = () => {
  const data = useContext(DataContext);
  const scrollRef = useRef(null);

  useEffect(() => {
    data.handleScrollSnap(scrollRef.current);
    data.setIsBackgroundAnimated(true);
    data.setIsMbmDifference(true);
  }, []);

  return (
    <div className="wrapper__news">
      <Helmet>
        <title>{`${data.artistName} — News`}</title>
      </Helmet>
      <div className="container__news">
        <ContainerTitle
          title={"NEWS"}
          subNavLanguages={data.languages}
          subNavLinks={false}
        />
        <div className="container__content sst--YMAND" ref={scrollRef} id="scroll--INSIDE" style={{height: `${data.maxHeight}px`}}>
          <div className="container__all sst--START" id="scroll--INSIDE--news" style={{height: `${data.maxHeight}px`}}>
            {data.news.length >= 1 ? (
              <motion.ul
                className="news--items"
                variants={data.animationContainer}
                initial="hidden"
                animate={data.isAllDataLoad && "visible"}
              >
                {data.news
                  .sort((a, b) => a.order - b.order)
                  .map((el) => (
                    <motion.li
                      className={"item"}
                      key={el.order}
                      variants={data.animationItem}
                    >
                      <div className="title--part">
                        <ReactMarkdown className="small__font">
                          {data.defaultLanguage.shortcut === "eng"
                            ? el.title_eng
                            : el.title_eng}
                        </ReactMarkdown>
                        <CustomFont
                          tag={"p"}
                          className={"small__font t--CAPS"}
                          content={
                            data.defaultLanguage.shortcut === "eng"
                              ? el.type_eng
                              : el.type_ger
                          }
                        />
                      </div>
                      <div className="content--part">
                        <ReactMarkdown className="small__font">
                          {data.defaultLanguage.shortcut === "eng"
                            ? el.content_eng
                            : el.content_ger}
                        </ReactMarkdown>
                      </div>
                      <div className="informations--part">
                        <ReactMarkdown className="small__font p--LINE">
                          {data.defaultLanguage.shortcut === "eng"
                            ? el.informations_eng
                            : el.informations_ger}
                        </ReactMarkdown>
                        {el.link && (
                          <ExternalLink
                            href={el.link}
                            className={"small__font c--GREY"}
                            content={"Website"}
                          />
                        )}
                      </div>
                    </motion.li>
                ))}
              </motion.ul>
            ) : (
              <CustomFont
                tag={"p"}
                className={"small__font"}
                content={
                  data.defaultLanguage.shortcut === "eng"
                    ? "There is no news at the moment."
                    : "Zurzeit gibt es keine News."
                }
              />
            )}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default News;
