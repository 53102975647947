import React, { useContext } from "react";
import DataContext from "../../config/dataContext";
import CustomFont from "../CustomFont";
import SubNavLanguages from "../SubNavLanguages";
import SubNavLinks from "../SubNavLinksBibliography";
import SubNavLinksWorks from "../SubNavLinksWorks";
import "./containerTitle.scss";

const ContainerTitle = ({ title, subNavLanguages, subNavLinksBibliography, subNavLinksWorks }) => {

  const data = useContext(DataContext);
  
  return (
    <div
      className={"container__top--title MBM--dif"}
      id="start--ANIM--title"
    >
      <div className="container__title">
        <CustomFont
          tag="h1"
          className="small__font f--CONTENT"
          content={title}
          onClick={data.handleScrollToTop}
        />
      </div>
      {subNavLinksBibliography ? <SubNavLinks /> : null}
      {subNavLanguages.length >= 1 ? (
        <SubNavLanguages subNavLanguages={subNavLanguages} />
      ) : null}
      {subNavLinksWorks ? <SubNavLinksWorks /> : null}
    </div>
  );
};

export default ContainerTitle;
