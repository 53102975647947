import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import DataContext from "../../config/dataContext";
import { motion } from "framer-motion/dist/framer-motion";
import Work from "../../components/Work";
import CustomFont from "../../components/CustomFont";
import ContainerTitle from "../../components/ContainerTitle";
import SubNavLinksWorks from "../../components/SubNavLinksWorks";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import "./works.scss";

const Works = () => {
  const data = useContext(DataContext);
  const scrollRef = useRef(null);
  const { workCategory } = useParams();
  const { workSerie } = useParams();
  const [worksToDisplay, setWorksToDisplay] = useState([]);

  useEffect(() => {
    // data.handleScrollSnap(scrollRef.current);
    data.setIsBackgroundAnimated(true);
    data.setIsMbmDifference(false);
  }, []);

  useEffect(() => {
    if (data.isAllDataLoad === true) {
      data.scrollSnapToTop(scrollRef.current);
      if (workCategory != undefined) {
        setWorksToDisplay(
          data.works.filter((work) =>
            data
              .formatLink(JSON.stringify(work.work_categories))
              .includes(workCategory)
          )
        );
      } else if (workSerie != undefined) {
        setWorksToDisplay(
          data.works.filter((work) => {
            if (
              work.work_series[0] &&
              data.formatLink(work.work_series[0].name) == workSerie
            ) {
              return work;
            }
          })
        );
      } else {
        // ! NEW
        setWorksToDisplay(data.works.filter((work) => work.new === true));
      }
    }
  }, [data.isAllDataLoad, workCategory, workSerie]);

  const handleSubNav = () => {
    data.setIsUnderLink(false);
    data.setHighlightLink({}) // ! {}
  };

  return (
    <div className="wrapper__works">
      <Helmet>
        {workCategory != undefined ? (
          <title>{`${data.artistName} — ${
            data.workCategories.find(
              (cat) => data.formatLink(cat.name) == workCategory
            )?.name
          } (category)`}</title>
        ) : (
          <title>{`${data.artistName} — ${
            data.workSeries.find(
              (ser) => data.formatLink(ser.name) == workSerie
            )?.name
          } (serie)`}</title>
        )}
      </Helmet>
      <div className="container__works">
        <ContainerTitle
          title={data.defaultLanguage.shortcut === "eng" ? "WORKS" : "WERKE"}
          subNavLanguages={false}
          subNavLinks={false}
          subNavLinksWorks={true}
        />
        <SubNavLinksWorks isMobile={true} />
        <div
          // className="container__content sst--YMAND"
          // ref={scrollRef}
          // id="scroll--INSIDE"
          className="container__content"
          style={{
            // height: `${data.maxHeight}px`,
            opacity: data.isUnderLink ? 0.25 : 1,
          }}
          onClick={handleSubNav}
        >
          <div className="wrapper__works">
            <div
              className="container__works sst--YMAND"
              // className="container__works"
              id="scroll--INSIDE"
              ref={scrollRef}
              style={{
                height: `${data.maxHeight}px`,
              }}
            >
              {worksToDisplay
                .sort((a, b) => b.sequ_number - a.sequ_number)
                .sort((a, b) => a.title.localeCompare(b.title))
                .sort((a, b) => b.year - a.year)
                .sort((a, b) => b.order - a.order)
                .map((work, i) => (
                  <Work work={work} key={JSON.stringify(work)} link="works" />
                ))}
              <Footer />
            </div>
          </div>
          {worksToDisplay.length >= 2 && (
            <motion.div
              className={"wrapper__btn--scroll"}
              animate={{
                y: data.isFooterVisible ? "4rem" : 0,
              }}
              transition={{
                ease: "easeInOut",
                default: { duration: 0.3 },
              }}
            >
              <CustomFont
                tag={"p"}
                className={"small__font c--GREY"}
                content={"↓"}
                onClick={data.handleArrowScroll}
              />
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Works;
