import React, { useContext, useEffect, useRef, useState } from 'react';
import { API_URL } from '../../config/config';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import DataContext from '../../config/dataContext';
import CustomFont from '../../components/CustomFont';
import Work from '../../components/Work';
import ContainerTitle from '../../components/ContainerTitle';
import { motion } from 'framer-motion/dist/framer-motion';
import Footer from '../../components/Footer';
import './showcase.scss';

const Showcase = () => {
  const data = useContext(DataContext);
  const scrollRef = useRef(null);
  const { showcaseId } = useParams();
  const [showcase, setShowcase] = useState({});
  const [isValidPassword, setIsValidPassword] = useState(false);
  const history = useHistory();

  useEffect(() => {
    data.handleScrollSnap(scrollRef.current);
    data.setIsBackgroundAnimated(true);
    data.setIsMbmDifference(false);

    fetch(`${API_URL}/showcases/${showcaseId}`)
    .then((response) => response.json())
    .then((data) => {
      setShowcase(data);
    })
    .catch(() => {
      history.push("/error404")
    });

  }, []);

  const handleForm = (e) => {
    if (e.target.value === showcase.password) {
      setIsValidPassword(true)
    }
  }
  
  return (
    <div className="wrapper__showcase">
      <Helmet>
        <title>{`${data.artistName} — Showcase #${showcaseId}`}</title>
      </Helmet>
      <motion.div
        className={isValidPassword ? "container__password transparent" : "container__password"}
        animate={{
          opacity: isValidPassword ? 0 : 1
        }}
        transition={{
          opacity: { easing: "easeInOut", duration: 0.5 }
        }}
        style={{height: `${data.maxHeight}px`}}
      >
        <input
          type="password"
          placeholder='password' 
          className='big__font t--CAPS input--PASSWORD'
          onChange={() => handleForm(event)}
          autoFocus={true}
          autoComplete="off"
        />
      </motion.div>
      <motion.div
        className={isValidPassword ? "container__showcase" : "container__showcase transparent"}
        animate={{
          opacity: isValidPassword ? 1 : 0
        }}
        transition={{
          opacity: { easing: "easeInOut", duration: isValidPassword ? 0.5 : 0, delay: isValidPassword ? 0.25 : 0 }
        }}
      >
        <ContainerTitle
          title={`SHOWCASE #${showcaseId}`}
          subNavLanguages={data.languages}
          subNavLinks={false}
          subNavLinksWorks={false}
        />
        <div
          className="container__content sst--YMAND"
          ref={scrollRef}
          id="scroll--INSIDE"
          style={{height: `${data.maxHeight}px`}}
        >
          <div className="wrapper__works">
            <div className="container__works">
              <div className="wrapper__description sst--START" style={{height: `${data.maxHeight}px`}}>
                <div className="container__description">
                  <CustomFont
                    content={
                      data.defaultLanguage.shortcut === "eng"
                      ? `to ${showcase.to}`
                      : `an ${showcase.to}`
                    }
                    className={"small__font t--CAPS"}
                    tag={"p"}
                  />
                  <div className="warnings">
                    <CustomFont
                      content={
                        data.defaultLanguage.shortcut === "eng"
                        ? "This custom showcase is stricly confidential."
                        : "Dieser kundenspezifische Schaukasten ist streng vertraulich."
                      }
                      className={"small__font"}
                      tag={"p"}
                    />
                    <CustomFont
                      content={
                        data.defaultLanguage.shortcut === "eng"
                        ? "Please let us know when this showcase is no longer useful."
                        : "Bitte lassen Sie uns wissen, wenn dieser Schaukasten nicht mehr nützlich ist."
                      }
                      className={"small__font"}
                      tag={"p"}
                    />
                  </div>
                  <div className="content__list">
                    <CustomFont
                      content={
                        data.defaultLanguage.shortcut === "eng"
                        ? "This custom showcase includes the following works:"
                        : "Diese benutzerdefinierte Vitrine umfasst die folgenden Werke:"
                      }
                      className={"small__font"}
                      tag={"p"}
                    />
                    <ul className="list">
                    {showcase.works && showcase.works
                      .sort((a, b) => b.sequ_number - a.sequ_number)
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .sort((a, b) => b.year - a.year)
                      .map((work, i) => 
                        <li className="item" key={i}>
                          <CustomFont
                            content={`${work.full_title}`}
                            className={"small__font"}
                            tag={"p"}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              {showcase.works && showcase.works
                .sort((a, b) => b.sequ_number - a.sequ_number)
                .sort((a, b) => a.title.localeCompare(b.title))
                .sort((a, b) => b.year - a.year)
                .map((work, i) => 
                  <Work work={work} key={i}/>
                )}
              <Footer />
              <motion.div
                className={"wrapper__btn--scroll"}
                animate={{
                  y: data.isFooterVisible ? "4rem" : 0
                }}
                transition={{
                  ease: "easeInOut",
                  default: { duration: 0.3 },
                }}
              >
                <CustomFont
                  tag={"p"}
                  className={"small__font c--GREY"}
                  content={"↓"}
                  onClick={data.handleArrowScroll}
                />
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Showcase;
