import React, { useContext } from "react";
import DataContext from "../../config/dataContext";
import "./bioNavLink.scss";

const BioNavLink = ({ navLinks, handleNav }) => {
  const data = useContext(DataContext);

  return (
    <ul className="container__nav--bio">
      {navLinks.map((link, i) => 
        <li
          key={i}
          className={link.isActive === true ? "small__font t--CAPS" : "small__font t--CAPS external-LINK c--GREY"}
          onClick={() => handleNav(link)}
        >
          <a href={"#" + link.slug}>
            {data.defaultLanguage.shortcut === "eng"
              ? `${link.name_eng}`
              : `${link.name_ger}`}
          </a>
        </li>
      )}
    </ul>
  );
};

export default BioNavLink;
