import React, { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import DataContext from '../../config/dataContext';
import Work from '../../components/Work';
import ContainerTitle from '../../components/ContainerTitle';
import CustomFont from '../../components/CustomFont';
import { motion } from "framer-motion/dist/framer-motion";
import Footer from '../../components/Footer';
import { Helmet } from "react-helmet";
import './detailsShow.scss';

const DetailsShow = () => {
  const data = useContext(DataContext);
  const scrollRef = useRef(null);
  const { showID } = useParams();
  const show = data.shows.find(show => show.id == showID);

  useEffect(() => {
    data.handleScrollSnap(scrollRef.current);
    data.setIsBackgroundAnimated(true);
    data.setIsMbmDifference(false);
  }, []);

  return (
    <div className="wrapper__works">
      <Helmet>
        <title>{`${data.artistName} — ${show?.title}`}</title>
      </Helmet>
      <div className="container__works">
        <ContainerTitle
          title={show?.title}
          subNavLanguages={data.languages}
          subNavLinks={false}
          subNavLinksWorks={false}
        />
        <div
          className="container__content sst--YMAND"
          ref={scrollRef}
          id="scroll--INSIDE"
          style={{height: `${data.maxHeight}px`}}
        >
          <div className="wrapper__works">
            <div className="container__works">
              {data.isAllDataLoad && show.works.map((work, i) => 
                <Work work={work} link="works" key={i}/>
              )}
              {data.isAllDataLoad && <Work work={show} link="shows"/>}
              <Footer />
            </div>
          </div>
          <motion.div className="wrapper__btn--back"
            animate={{
              x: data.isFooterVisible ? "-13rem" : 0
            }}
            transition={{
              ease: "easeInOut",
              default: { duration: 0.3 },
            }}
          >
            <Link
              to={"/shows"}
              className={"small__font c--GREY external-LINK"}
            >
              {data.defaultLanguage.shortcut === "eng"
              ? "Back to shows"
              : "Zurück zu Austellungen"}
            </Link>
          </motion.div>
          <motion.div
            className={"wrapper__btn--scroll"}
            animate={{
              y: data.isFooterVisible ? "4rem" : 0
            }}
            transition={{
              ease: "easeInOut",
              default: { duration: 0.3 },
            }}
          >
            <CustomFont
              tag={"p"}
              className={"small__font c--GREY"}
              content={"↓"}
              onClick={data.handleArrowScroll}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default DetailsShow;