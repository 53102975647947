import React, { useContext, useEffect, useRef } from "react";
import ContainerTitle from "../../components/ContainerTitle";
import CustomFont from "../../components/CustomFont";
import DataContext from "../../config/dataContext";
import ReactMarkdown from "react-markdown";
import Footer from "../../components/Footer";
import { motion } from 'framer-motion/dist/framer-motion';
import { Helmet } from "react-helmet";
// import { container, item } from "../../assets/animation/list";
// import { v4 as uuidv4 } from "uuid";
import "./imprint.scss";

const Imprint = () => {
  const data = useContext(DataContext);
  const scrollRef = useRef(null);

  useEffect(() => {
    data.handleScrollSnap(scrollRef.current);
    data.setIsBackgroundAnimated(true);
    data.setIsMbmDifference(true);
  }, []);

  return (
    <div className="wrapper__imprint">
      <Helmet>
        <title>{`${data.artistName} — Imprint`}</title>
      </Helmet>
      <div className="container__imprint">
        <ContainerTitle
          title={"IMPRINT"}
          subNavLanguages={data.languages}
          subNavLinks={false}
        />
        <div
          className="container__content sst--YMAND"
          ref={scrollRef}
          id="scroll--INSIDE"
          style={{height: `${data.maxHeight}px`}}
        >
          <div className="container__all sst--START" id="scroll--INSIDE--imprint" style={{height: `${data.maxHeight}px`}}>
            <motion.ul
              className="container__legals"
              variants={data.animationContainer}
              initial="hidden"
              animate={data.isAllDataLoad && "visible"}
            >
              {data.imprint.title_description?.map((el, i) => 
                <motion.li
                  className="legals--item"
                  key={i + 1}
                  variants={data.animationItem}
                >
                  <CustomFont
                    tag={"h2"}
                    className={"small__font t--CAPS"}
                    content={
                      data.defaultLanguage.shortcut === "eng"
                      ? el.title_eng
                      : el.title_ger
                    }
                  />
                  <ReactMarkdown
                    className="small__font md--RULES p--md--RULES"
                    linkTarget="_blank"
                    children="string"
                  >
                    {data.defaultLanguage.shortcut === "eng"
                      ? el.description_eng
                      : el.description_ger}
                  </ReactMarkdown>
                </motion.li>
              )}
            </motion.ul>
            <div
              className="container__credits"
            >
              <ReactMarkdown
                className="small__font md--RULES"
                linkTarget="_blank"
              >
                {data.defaultLanguage.shortcut === "eng"
                  ? data.imprint.credits_eng
                  : data.imprint.credits_ger}
              </ReactMarkdown>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Imprint;
