import React, { useState, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import DataContext from "../../config/dataContext";
import CustomFont from '../CustomFont';
import { wrap } from "popmotion/dist/popmotion";
import "./slider.scss";

const Slider = ({ data, isZoomable, isOpacity }) => {
  const x = useContext(DataContext);

  const sortedPNG = (string) => {
    let arr = string.split('_')
    // return parseInt(string.split('.')[0].split('_').slice(-1), 10)
    // return arr[arr.length - 1]
    return parseInt(arr[arr.length - 1])
  }

  const sortedMedias = data.medias.sort((a, b) => sortedPNG(a.name) - sortedPNG(b.name));
  const [[page, direction], setPage] = useState([0, 0]);
  const [isZoomed, setIsZoomed] = useState(false);
  const [imgNumber, setImgNumber] = useState(1);
  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0)

  const imageIndex = wrap(0, sortedMedias.length, page);

  const paginate = (newDirection) => {
    setIsZoomed(false)
    setPage([page + newDirection, newDirection]);
    if (newDirection === -1 && imgNumber === 1) {
      setImgNumber(data.medias.length)
    } else if (newDirection === -1 && imgNumber > 1) {
      setImgNumber(imgNumber - 1)
    } else if (newDirection === 1 && imgNumber === data.medias.length) {
      setImgNumber(1)
    } else {
      setImgNumber (imgNumber + 1)
    }
  };

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? '100%' : '-100%'
      };
    },
    center: {
      zIndex: 1,
      x: 0
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? '100%' : '-100%'
      };
    },
  };

  const handleZoom = (e) => {
    setIsZoomed(!isZoomed)
    getXYpos(e)
  }

  const handleMoovement = (e) => {
    getXYpos(e)
  }

  const getXYpos = (e) => {
    let clientWidth = window.innerWidth
    let clientHeight = window.innerHeight
    let x = e.clientX / clientWidth * 100
    let y = e.clientY / clientHeight * 100
    setXPos(-x + 50)
    setYPos(-y + 50)
  }

  const handlePaginate = (value) => {
    paginate(value)
    x.setHighlightLink({}) // ! {}
  }

  return (
    <div className="wrapper__slider" style={{opacity: isOpacity ? 0.25 : 1}}>
      <div className="container__slider">
        {data.medias.length >= 2 ?        
          <AnimatePresence initial={false} custom={direction}>
            <motion.div className="container--img"
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { easing: "easeInOut", duration: 0.5 }
              }}
            >
              <motion.img
                src={sortedMedias[imageIndex].url}
                loading="lazy"
                alt={`Sébastien de Ganay ${data.full_title} work`}
                className={isZoomed ? "is--ZOOM" : ""}
                animate={{
                  scale: isZoomed ? 2 : 1,
                  x: isZoomed ? `${xPos}%` : 0,
                  y: isZoomed ? `${yPos}%` : 0
                }}
                transition={{
                  scale: { type: "easeInOut", duration: isZoomed ? 0 : 0.5 },
                  x: { duration: isZoomed ? 0 : 0.5 },
                  y: { duration: isZoomed ? 0 : 0.5 }
                }}
              />
            </motion.div>
          </AnimatePresence>
        : 
          <div className="container--img">
          <motion.img
            src={sortedMedias[imageIndex].url}
            loading="lazy"
            alt={`Sébastien de Ganay ${data.full_title} work`}
            animate={{
              scale: isZoomed ? 2 : 1,
              x: isZoomed ? `${xPos}%` : 0,
              y: isZoomed ? `${yPos}%` : 0
            }}
            transition={{
              scale: { type: "easeInOut", duration: isZoomed ? 0 : 0.5},
              x: { duration: isZoomed ? 0 : 0.5 },
              y: { duration: isZoomed ? 0 : 0.5 }
            }}
          />
        </div>
        }
        {isZoomable ? 
          data.medias.length >= 2 ?
            <>
              <div
                className={isZoomed ? "area--PREV is--ZOOM" : "area--PREV"}
                onClick={() => handlePaginate(-1)}
              >
                <CustomFont
                  tag={"p"}
                  content={"←"}
                  className={"small__font arrow"}
                />
              </div>
              <div
                className={isZoomed ? "area--FULL--ZOOM is--ZOOM" : "area--ZOOM"}
                onClick={() => handleZoom(event)}
                onMouseMove={isZoomed ? () => handleMoovement(event) : undefined}
              ></div>
              <div 
                className={isZoomed ? "area--NEXT is--ZOOM" : "area--NEXT"}
                onClick={() => handlePaginate(1)}
              >
                <CustomFont
                  tag={"p"}
                  content={"→"}
                  className={"small__font arrow"}
                />
              </div>
            </>
          : 
            <div className={isZoomed ? "area--FULL--ZOOM is--ZOOM" : "area--FULL--ZOOM"}
              onClick={() => handleZoom(event)}
              onMouseMove={isZoomed ? () => handleMoovement(event) : undefined}
            ></div>
        :
          data.medias.length >= 2 &&
          <>
            <div
              className={"area--PREV no--ZOOM"}
              onClick={() => handlePaginate(-1)}
            >
              <CustomFont
                tag={"p"}
                content={"←"}
                className={"small__font arrow"}
              />
            </div>
            <div 
              className={"area--NEXT no--ZOOM"}
              onClick={() => handlePaginate(1)}
            >
              <CustomFont
                tag={"p"}
                content={"→"}
                className={"small__font arrow"}
              />
            </div>
          </>
        }
      </div>
      {data.medias.length >= 2 && 
        <div className="container__index--img">
          <CustomFont
            tag={"p"}
            className={"small__font"}
            content={`${imgNumber}/${data.medias.length}`}
          />
        </div>
      }
    </div>
  );
};

export default Slider;
