import React, { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import DataContext from '../../config/dataContext';
import { Helmet } from 'react-helmet';
import Work from '../../components/Work';
import ContainerTitle from '../../components/ContainerTitle';
import SubNavLinksWorks from '../../components/SubNavLinksWorks';
import Footer from '../../components/Footer';

const UniqWork = () => {
  const data = useContext(DataContext);
  const scrollRef = useRef(null);
  const { workID } = useParams();
  const work = data.works.find(work => work.id == workID);

  useEffect(() => {
    data.handleScrollSnap(scrollRef.current);
    data.setIsBackgroundAnimated(true);
    data.setIsMbmDifference(false);
  }, []);

  const handleSubNav = () => {
    data.setIsUnderLink(false)
    data.setHighlightLink({}) // ! {}
  }

  return (
    <div className="wrapper__works">
      <Helmet>
        <title>{`${data.artistName} — ${work?.full_title}`}</title>
      </Helmet>
      <div className="container__works">
        <ContainerTitle
          title={"WORKS"}
          subNavLanguages={false}
          subNavLinks={false}
          subNavLinksWorks={true}
        />
        <SubNavLinksWorks isMobile={true} />
        <div
          className="container__content sst--YMAND"
          ref={scrollRef}
          id="scroll--INSIDE"
          style={{
            height: `${data.maxHeight}px`,
            opacity: data.isUnderLink ? 0.25 : 1
          }}
          onClick={handleSubNav}
        >
          <div className="wrapper__works">
            <div className="container__works">
              {data.isAllDataLoad && <Work work={work} />}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniqWork;