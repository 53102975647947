import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
// import createScrollSnap from "scroll-snap";
import { motion } from 'framer-motion/dist/framer-motion';
import smoothscroll from 'smoothscroll-polyfill';
// import { polyfill } from 'smoothscroll-polyfill';
import { fetchData } from "./config/fetchData";
import DataContext from "./config/dataContext";
import Header from "./components/Header";
import Menu from "./components/Menu";
import Works from "./Pages/Works";
import Contact from "./Pages/Contact";
import News from "./Pages/News";
import Imprint from "./Pages/Imprint";
import Error404 from "./Pages/Error404";
import Publications from "./Pages/Publications";
import Home from "./Pages/Home";
import AnimatedBackground from "./components/AnimatedBackground";
import Showcase from "./Pages/Showcase";
import UniqWork from "./Pages/UniqWork";
import Search from "./Pages/Search";
import Shows from "./Pages/Shows";
import UniqShow from "./Pages/UniqShow";
import DetailsShow from "./Pages/DetailsShow";
import Biography from "./Pages/Biography";
import "./styles/reset.scss";
import "./index.scss";

const App = () => {

  // ! REMOVE TO DEVELOP //
  // useEffect(() => {
  //   if (window.location.protocol === "http:") {
  //     window.location.href = "https://" + window.location.host + window.location.pathname;
  //   }
  // }, [])
  // ! REMOVE TO DEVELOP //

  smoothscroll.polyfill()
  const artistName = 'SÉBASTIEN DE GANAY'
  const [works, setWorks] = useState([]);
  const [worksIsLoaded, setWorksIsLoaded] = useState(false);
  const [shows, setShows] = useState([]);
  const [showsIsLoaded, setShowsIsLoaded] = useState(false);
  const [workTypes, setWorkTypes] = useState([]);
  const [workTypesIsLoaded, setWorkTypesIsLoaded] = useState(false);
  const [workSeries, setWorkSeries] = useState([]);
  const [workSeriesIsLoaded, setWorkSeriesIsLoaded] = useState(false);
  const [workCategories, setWorkCategories] = useState([]);
  const [workCategoriesIsLoaded, setWorkCategoriesIsLoaded] = useState(false);
  const [soloExhibitions, setSoloExhibitions] = useState([]);
  const [soloExhibitionsIsLoaded, setSoloExhibitionsIsLoaded] = useState(false);
  const [groupExhibitions, setGroupExhibitions] = useState([]);
  const [groupExhibitionsIsLoaded, setGroupExhibitionsIsLoaded] = useState(false);
  const [artFairs, setArtFairs] = useState([]);
  const [artFairsIsLoaded, setArtFairsIsLoaded] = useState(false);
  const [artistBooks, setArtistBooks] = useState([]);
  const [artistBooksIsLoaded, setArtistBooksIsLoaded] = useState(false);
  const [artistFilms, setArtistFilms] = useState([]);
  const [artistFilmsIsLoaded, setArtistFilmsIsLoaded] = useState(false);
  const [awards, setAwards] = useState([]);
  const [awardsIsLoaded, setAwardsIsLoaded] = useState(false);
  const [news, setNews] = useState([]);
  const [newsIsLoaded, setNewsIsLoaded] = useState(false);
  const [publications, setPublications] = useState([]);
  const [publicationsIsLoaded, setPublicationsIsLoaded] = useState(false);
  const [galleries, setGalleries] = useState([]);
  const [galleriesIsLoaded, setGalleriesIsLoaded] = useState(false);
  const [informations, setInformations] = useState([]);
  const [informationsIsLoaded, setInformationsIsLoaded] = useState(false);
  const [imprint, setImprint] = useState([]);
  const [imprintIsLoaded, setImprintIsLoaded] = useState(false);
  const [error, setError] = useState([]);
  const [errorIsLoaded, setErrorIsLoaded] = useState(false);
  const [highlightLink, setHighlightLink] = useState({}); // ! {}
  const [isMbmDifference, setIsMbmDifference] = useState(false);  
  const [maxWidth, setMaxWidth] = useState();
  const [maxHeight, setMaxHeight] = useState();

  window.addEventListener('resize', function(){
    setMaxHeight(window.innerHeight)
    setMaxWidth(window.innerWidth)
  });

  // useEffect(() => {
  //   // smoothscroll.polyfill();
  //   polyfill();
  //   console.log('polyfill')
  // })

  // Start at false => if all data is loaded => true
  const [isAllDataLoad, setIsAllDataLoad] = useState(false);

  // Root category
  const [rootCategorySlug, setRootCategorySlug] = useState("");

  // Root category
  const [isUnderLink, setIsUnderLink] = useState(false);

  // State to verify is footer is visible to trigger animation from logo
  const [isFooterVisible, setIsFooterVisible] = useState(false);

  // Toggle Menu class hidden
  const [isMenuActive, setIsMenuActive] = useState();
  const [isMenuClosing, setIsMenuClosing] = useState(false);
  
  // Toggle FilterWorks;
  const [isFilterWorks, setIsFilterWorks] = useState(false);

  // Toggle FilterWorks;
  const [isFilterBiography, setIsFilterBiography] = useState(false);

  const handleIsFilterWorks = () => {
    setIsFilterWorks(!isFilterWorks);
  }

  const handleIsFilterBiography = () => {
    setIsFilterBiography(!isFilterBiography);
  }

  // LOGO SIZE BASED ON THE isFooterVisible when toggle Menu
  const handleMenu = () => {
    setIsMenuActive(!isMenuActive)
    if (isMenuActive === false || isFooterVisible === false) {
      setIsLogoBig(false)
    } else if (isMenuActive === undefined || isFooterVisible === false) {
      setIsLogoBig(false)
    } else if (isMenuActive === true || isFooterVisible === true) {
      setIsLogoBig(true)
    }
  }

  // setIsMenuClosing to delay Animation
  useEffect(() => {
    if (isMenuActive === true) {
      setIsMenuClosing(false)
    } else if (isMenuActive === false) {
      setIsMenuClosing(true)
    }
  }, [isMenuActive])

  // ANIMATION CONTAINER + ITEMS
  const animationContainer = {
    hidden: {},
    visible: {
      transition: {
        delayChildren: isMenuClosing ? 0.1 : 1,
        staggerChildren: 0.1
      }
    }
  };
  
  const animationItem = {
    hidden: { y: '-3rem', opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    }
  };

  // Languages
  const languages = [
    {name: "english", shortcut: "eng"},
    {name: "deutsch", shortcut: "ger"},
  ];
  const [defaultLanguage, setDefaultLanguage] = useState({
    name: "english",
    shortcut: "eng",
  });

  //BIBLIOGRAPHY LINKS
  const [bibliographyLinks, setBibliographyLinks] = useState([
    {name: "solo exhibitions", slug: "/biography/solo-exhibitions", isActive: false},
    {name: "group exhibitions", slug: "/biography/group-exhibitions", isActive: false},
    {name: "art fairs", slug: "/biography/art-fairs", isActive: false},
    {name: "artist books", slug: "/biography/artist-books", isActive: false},
    {name: "artist films", slug: "/biography/artist-films", isActive: false},
    {name: "awards", slug: "/biography/awards", isActive: false}
  ]);

  const [worksLinks, setWorksLinks] = useState([])

  useEffect(() => {
    if (isAllDataLoad === true) {
      setWorksLinks(
        workTypes.map((type) => {
          return {name: type.name, slug: type.name.toLowerCase(), isActive: false, order: type.order}
        })
      )
      setRootCategorySlug(
        formatLink(workCategories.sort((a, b) => a.order - b.order)[0].name)
      )
      setMaxWidth(window.innerWidth)
    }
  }, [isAllDataLoad])

  const scrollInside = document.getElementById("scroll--INSIDE")

  const scrollSnapToTop = (el) => {
    setTimeout(() => {
      el.scrollTo(0, 0)
    }, 10)
  }

  useEffect(() => {
    if (isAllDataLoad === true) {
      scrollInside && scrollSnapToTop(scrollInside)
    }
  }, [window.location.pathname, isAllDataLoad])

  // Logo size for animation
  const [isLogoBig, setIsLogoBig] = useState(true);

  // Boolean to trigger the breathing animation
  const [isBackgroundAnimated, setIsBackgroundAnimated] = useState(true);

  // SMOOTH ANIMATION TO SCROLL TO TOP
  const handleScrollToTop = (value) => {
    // EVERY ID TO SCROLL TO TOP WHEN CLICK ON LOGO
    const everyPageScroll = document.getElementById("scroll--INSIDE");
    const bibliographyPageScroll = document.getElementById("scroll--INSIDE--bibliography");
    const newsPageScroll = document.getElementById("scroll--INSIDE--news");
    const imprintPageScroll = document.getElementById("scroll--INSIDE--imprint");
    const allPublications = document.getElementsByClassName("section__publication");

    if (isMenuActive === false || isMenuActive === undefined) {
      everyPageScroll.scrollTo({
        top: 0,
        behavior: value === 0 ? "auto" : "smooth",
      });
      if (bibliographyPageScroll) {
        bibliographyPageScroll.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      if (newsPageScroll) {
        newsPageScroll.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      if (imprintPageScroll) {
        imprintPageScroll.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      if (allPublications) {
        [...allPublications].map((publication) => {
          publication.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        })
      }
    }
  };

  // Scroll Snap Custom
  const handleScrollSnap = (target) => {
    // createScrollSnap(
    //   target,
    //   {
    //     // VALEUR CHELOU À CHANGER (firefox bugg => -100%)
    //     snapDestinationY: "100%",
    //     timeout: 100,
    //     duration: 550,
    //     threshold: 0.45,
    //   },
    //   () => handleObserveFooter()
    // );
  };

  const [isScrolling, setIsScrolling] = useState(false)

  const handleScrollSnapType = (value) => {
    let els = Array.from(document.getElementsByClassName('sst--YMAND'))
    els.map((el) => el.style.scrollSnapType = value ? 'y mandatory' : 'inherit')
  }
  
  const handleArrowScroll = () => {
    if (isScrolling === false) {
      setIsScrolling(true)
      handleScrollSnapType(false)
      let v = maxHeight;
      let p = scrollInside.scrollTop + v;
      scrollInside.scroll({
        top: p,
        behavior: 'smooth'
      });
      setTimeout(() => {
        handleScrollSnapType(true)
        setIsScrolling(false)
      }, 700)
    }
  }

  // const btnScroll = document.getElementsByClassName('wrapper__btn--scroll')
  // useEffect(() => {
  //   btnScroll && scrollInside && document.addEventListener('keydown', function(e) {
  //     switch (e.keyCode) {
  //       case 40:
  //         handleArrowScroll()
  //     }
  // });
  // }, [btnScroll, scrollInside])

  // Intersection Observer on Footer to trigger the animation from the LOGO & the Background
  const handleObserveFooter = () => {
    var observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true) {
          setIsFooterVisible(true);          
          setIsBackgroundAnimated(true);
          isMenuActive === false && setIsLogoBig(true)
          isMenuActive === undefined && setIsLogoBig(true)
        }
        else {
          setIsFooterVisible(false);
          setIsLogoBig(false)
        }
      },
      { threshold: [0.5] }
    );
    observer.observe(document.getElementById("anim--FOOTER"));
  };
  isAllDataLoad && document.getElementById("anim--FOOTER") && handleObserveFooter();

  const formatLink = (link) => {
    return link.toLowerCase().split(' ').join('-')
  }

  useEffect(() => {
    fetchData(setWorks, "/works", setWorksIsLoaded);
    fetchData(setShows, "/shows", setShowsIsLoaded);
    fetchData(setWorkTypes, "/work-types", setWorkTypesIsLoaded);
    fetchData(setWorkCategories, "/work-categories", setWorkCategoriesIsLoaded);
    fetchData(setWorkSeries, "/work-series", setWorkSeriesIsLoaded);
    fetchData(setSoloExhibitions, "/solo-exhibitions", setSoloExhibitionsIsLoaded);
    fetchData(setGroupExhibitions, "/group-exhibitions", setGroupExhibitionsIsLoaded);
    fetchData(setArtFairs, "/art-fairs", setArtFairsIsLoaded);
    fetchData(setArtistBooks, "/artist-books", setArtistBooksIsLoaded);
    fetchData(setArtistFilms, "/artist-films", setArtistFilmsIsLoaded);
    fetchData(setAwards, "/awards", setAwardsIsLoaded);
    fetchData(setNews, "/news", setNewsIsLoaded);
    fetchData(setPublications, "/publications", setPublicationsIsLoaded);
    fetchData(setGalleries, "/galeries", setGalleriesIsLoaded);
    fetchData(setInformations, "/informations", setInformationsIsLoaded);
    fetchData(setImprint, "/imprint", setImprintIsLoaded);
    fetchData(setError, "/error", setErrorIsLoaded);
    setMaxHeight(window.innerHeight)
  }, []);

  useEffect(() => {
    if (
      worksIsLoaded === true &&
      showsIsLoaded === true &&
      workTypesIsLoaded === true &&
      workSeriesIsLoaded === true &&
      workCategoriesIsLoaded === true &&
      soloExhibitionsIsLoaded === true &&
      groupExhibitionsIsLoaded === true &&
      artFairsIsLoaded === true &&
      artistBooksIsLoaded === true &&
      artistFilmsIsLoaded === true &&
      awardsIsLoaded === true &&
      newsIsLoaded === true &&
      publicationsIsLoaded === true &&
      galleriesIsLoaded === true &&
      informationsIsLoaded === true &&
      imprintIsLoaded === true &&
      errorIsLoaded === true
    )
      setIsAllDataLoad(true);
  }, [
    worksIsLoaded,
    showsIsLoaded,
    workTypesIsLoaded,
    workSeriesIsLoaded,
    workCategoriesIsLoaded,
    soloExhibitionsIsLoaded,
    groupExhibitionsIsLoaded,
    artFairsIsLoaded,
    artistBooksIsLoaded,
    artistFilmsIsLoaded,
    awardsIsLoaded,
    newsIsLoaded,
    publicationsIsLoaded,
    galleriesIsLoaded,
    informationsIsLoaded,
    imprintIsLoaded,
    errorIsLoaded,
  ]);

  // IF ALL DATA IS LOADED, TRIGGER LOGO SIZE + ANIMATION
  useEffect(() => {
    if (isAllDataLoad === true) {
      setIsLogoBig(false)
    }
  }, [isAllDataLoad]);

  return (
    <DataContext.Provider
      value={{
        artistName: artistName,
        works: works,
        shows: shows,
        workTypes: workTypes,
        workSeries: workSeries,
        workCategories: workCategories,
        soloExhibitions: soloExhibitions,
        groupExhibitions: groupExhibitions,
        artFairs: artFairs,
        artistBooks: artistBooks,
        artistFilms: artistFilms,
        awards: awards,
        news: news,
        publications: publications,
        galleries: galleries,
        informations: informations,
        imprint: imprint,
        error: error,
        isMenuActive: isMenuActive,
        languages: languages,
        defaultLanguage: defaultLanguage,
        isLogoBig: isLogoBig,
        isAllDataLoad: isAllDataLoad,
        isBackgroundAnimated: isBackgroundAnimated,
        bibliographyLinks: bibliographyLinks,
        setBibliographyLinks: setBibliographyLinks,
        handleMenu: handleMenu,
        setIsMenuActive: setIsMenuActive,
        setDefaultLanguage: setDefaultLanguage,
        handleScrollSnap: handleScrollSnap,
        setIsBackgroundAnimated: setIsBackgroundAnimated,
        handleScrollToTop: handleScrollToTop,
        animationContainer: animationContainer,
        animationItem: animationItem,
        setIsMenuClosing: setIsMenuClosing,
        isMbmDifference: isMbmDifference,
        setIsMbmDifference: setIsMbmDifference,
        handleIsFilterWorks: handleIsFilterWorks,
        handleIsFilterBiography: handleIsFilterBiography,
        isFilterWorks: isFilterWorks,
        isFilterBiography: isFilterBiography,
        isFooterVisible: isFooterVisible,
        worksLinks: worksLinks,
        setWorksLinks: setWorksLinks,
        formatLink: formatLink,
        maxHeight: maxHeight,
        maxWidth: maxWidth,
        isUnderLink: isUnderLink,
        setIsUnderLink: setIsUnderLink,
        highlightLink: highlightLink,
        setHighlightLink: setHighlightLink,
        scrollSnapToTop: scrollSnapToTop,
        handleArrowScroll: handleArrowScroll,
        isScrolling: isScrolling
      }}
    >
      <Router>
        <div
          className={
            isAllDataLoad ? "wrapper__application true" : "wrapper__application"
          }
        >
          {/* <ScrollMemory /> */}
          <div className="container__application">
            <Header />
            <Menu />
            <motion.div
              className={isMbmDifference ? "wrapper__switch--main mbm-DIFFERENCE" : "wrapper__switch--main"}
              initial={{
                opacity: 1
              }}
              animate={{
                opacity: isMenuActive ? 0 : 1
              }}
              transition={{
                ease: "easeInOut",
                default: { duration: 0.5 },
                delay: isMenuActive ? 0 : .85
              }}
            >
              <motion.div
                className="wrapper__anim--start"
                initial={{
                  opacity: 0
                }}
                animate={{
                  opacity: isAllDataLoad ? 1 : 0
                }}
                transition={{
                  ease: "easeInOut",
                  default: { duration: 0.75 },
                  delay: 0
                }}
              >
                <Switch>
                  <Route path="/" exact render={() => <Home />}/>
                  {/* <Route path="/works" exact render={() => <Redirect to={`/works/category/${rootCategorySlug}`}/>} /> */}
                  <Route path="/works" exact render={() => <Redirect to={`/works/category/folded-flats`}/>} />
                  <Route path="/works/category/:workCategory" render={() => <Works />}/>
                  <Route path="/works/serie/:workSerie" render={() => <Works />}/>
                  {/* NEW WORKS */}
                  <Route path="/works/new" render={() => <Works />}/>
                  {/* NEW WORKS */}
                  <Route path="/works/:workID" render={() => <UniqWork />}/>
                  <Route path="/shows" exact render={() => <Shows />}/>
                  <Route path="/shows/:showID" exact render={() => <UniqShow />}/>
                  <Route path="/shows/:showID/details" render={() => <DetailsShow />}/>
                  <Route path="/showcase/:showcaseId" render={() => <Showcase />}/>
                  <Route path="/search" render={() => <Search />}/>
                  <Route path="/search=:searchParams" render={() => <Search />}/>
                  <Route path="/biography" exact render={() => <Biography />} />
                  <Route path="/publications" render={() => <Publications />} />
                  <Route path="/contact" render={() => <Contact />} />
                  <Route path="/news" render={() => <News />} />
                  <Route path="/imprint" render={() => <Imprint />} />
                  <Route path="*" render={() => <Error404 />} />
                </Switch>
              </motion.div>
            </motion.div>
          </div>
          <AnimatedBackground />
        </div>
      </Router>
    </DataContext.Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
