import React, { useContext, useEffect, useRef } from "react";
import ContainerTitle from "../../components/ContainerTitle";
import ExternalLink from "../../components/ExternalLink";
import DataContext from "../../config/dataContext";
import ReactMarkdown from "react-markdown";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import "./error404.scss";

const Error404 = () => {
  const data = useContext(DataContext);
  const scrollRef = useRef(null);

  useEffect(() => {
    data.handleScrollSnap(scrollRef.current);
    data.setIsBackgroundAnimated(true);
    data.setIsMbmDifference(true);
  }, []);

  return (
    <div className="wrapper__error">
      <Helmet>
        <title>{`${data.artistName} — Error 404`}</title>
      </Helmet>
      <div className="container__error">
        <ContainerTitle
          title={"404 ERROR"}
          subNavLanguages={data.languages}
          subNavLinks={false}
        />
        <div
          className="container__content sst--YMAND"
          ref={scrollRef}
          id="scroll--INSIDE"
          style={{height: `${data.maxHeight}px`}}
        >
          <div className="container__all sst--START" style={{minHeight: `${data.maxHeight}px`}}>
            <div className="container__description">
              <ReactMarkdown
                className="small__font md--RULES"
                linkTarget="_blank"
                children="string"
              >
                {data.defaultLanguage.shortcut === "eng"
                  ? data.error.description_eng
                  : data.error.description_ger}
              </ReactMarkdown>
            </div>
            <div className="container__link">
              <ExternalLink
                href={`mailto:${data.informations.mail}`}
                className={"small__font t--CAPS c--GREY"}
                content={
                  data.defaultLanguage.shortcut === "eng"
                    ? data.error.button_eng
                    : data.error.button_ger
                }
              />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Error404;
