import React, { useContext, useEffect, useRef } from "react";
import ContainerTitle from "../../components/ContainerTitle";
import DataContext from "../../config/dataContext";
import ContactLinks from "../../components/ContactLinks";
import ReactMarkdown from "react-markdown";
import CustomFont from "../../components/CustomFont";
import ExternalLink from "../../components/ExternalLink";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion/dist/framer-motion";
// import { container, item } from "../../assets/animation/list";
// import { v4 as uuidv4 } from "uuid";
import "./contact.scss";

const Contact = () => {
  const data = useContext(DataContext);
  const scrollRef = useRef(null);

  useEffect(() => {
    data.handleScrollSnap(scrollRef.current);
    data.setIsBackgroundAnimated(true);
    data.setIsMbmDifference(true);
  }, []);

  return (
    <div className="wrapper__contact">
      <Helmet>
        <title>{`${data.artistName} — Contact`}</title>
      </Helmet>
      <div className="container__contact">
        <ContainerTitle
          title={
            data.defaultLanguage.shortcut === "eng"
              ? "CONTACT"
              : "KONTAKT"
          }
          subNavLanguages={data.languages}
          subNavLinks={false}
        />
        <div
          className="container__content sst--YMAND"
          ref={scrollRef}
          id="scroll--INSIDE"
          style={{ height: `${data.maxHeight}px` }}
        >
          <div
            className="container__all sst--START"
            style={{ minHeight: `${data.maxHeight}px` }}
          >
            <div className="container__galeries">
              <motion.ul
                className={`galleries--items ${
                  data.galleries.length % 2 != 0 ? "is-ODD" : ""
                }`}
                variants={data.animationContainer}
                initial="hidden"
                animate={data.isAllDataLoad && "visible"}
              >
                {data.galleries
                  .sort((a, b) => a.order - b.order)
                  .map((gallery) => (
                    <motion.li
                      className="container__item"
                      variants={data.animationItem}
                      key={gallery.name}
                    >
                      <div className="item">
                        <div className="name--part">
                          <CustomFont
                            tag={"p"}
                            content={gallery.name}
                            className={"small__font"}
                          />
                        </div>
                        <div className="adress--part">
                          <ReactMarkdown className="small__font">
                            {data.defaultLanguage.shortcut === "eng"
                              ? gallery.adress_eng
                              : gallery.adress_ger}
                          </ReactMarkdown>
                        </div>
                        <div className="contact--part">
                          <ExternalLink
                            href={`mailto:${gallery.email}`}
                            className={"small__font c--GREY"}
                            content={"Mail"}
                          />
                          <ExternalLink
                            href={gallery.website}
                            className={"small__font c--GREY"}
                            content={"Website"}
                          />
                          <ExternalLink
                            href={`tel:${gallery.telephone}`}
                            className={"small__font c--GREY"}
                            content={"Tel."}
                          />
                        </div>
                      </div>
                    </motion.li>
                  ))}
              </motion.ul>
            </div>
            <ContactLinks first={true} />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Contact;
