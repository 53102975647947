import { createContext } from 'react';

const DataContext = createContext({
  // works: null,
  // workTypes: null,
  // workSeries: null,
  // workCategories: null,
  // soloExhibitions: null,
  // groupExhibitions: null,
  // artFairs: null,
  // artistBooks: null,
  // artistFilms: null,
  // awards: null,
  // news: null,
  // publications: null,
  // galleries: null,
  // informations: null,
  // imprint: null,
  // error: null
});

export default DataContext;