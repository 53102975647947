import React, { useContext, useState, useEffect } from "react";
import DataContext from "../../config/dataContext";
import CustomFont from "../CustomFont";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./subNavLinksWorks.scss";

const SubNavLinksWorks = ({ isMobile }) => {
  const data = useContext(DataContext);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    setLinks(data.worksLinks);
  }, [data.worksLinks]);

  useEffect(() => {
    if (data.isAllDataLoad === true) {
      let links = data.worksLinks.map((link) => {
        if (link.name === data.highlightLink.name) {
          return { ...link, isActive: true };
        }
        return { ...link, isActive: false };
      });
      setLinks(links);
    }
  }, [data.highlightLink]);

  const handleSubLinks = (link) => {
    if (!link) {
      data.setHighlightLink({}); // ! {}
      data.setIsUnderLink(true);
    } else {
      data.setHighlightLink(data.workTypes.find((el) => el.name === link.name));
      data.setIsUnderLink(true);
    }
  };

  const resetSubLinks = () => {
    data.handleScrollToTop(0);
    data.setHighlightLink({}); // ! {}
    data.setIsUnderLink(false);
  };

  const handleLeave = () => {
    data.setHighlightLink({}); // ! {}
    data.setIsUnderLink(false);
  };

  const seriesName = document.querySelectorAll(".serie--NAME");
  Array.from(seriesName).map((serie) => {
    serie.addEventListener("mouseover", function () {
      serie.parentNode.parentNode.firstChild.firstChild.firstChild.style.opacity = 1;
    });
    serie.addEventListener("mouseout", function () {
      serie.parentNode.parentNode.firstChild.firstChild.firstChild.style.opacity =
        "";
    });
  });

  const isNewWorks = data.works.filter((work) => work.new === true).length
    ? true
    : false;

  const handleNewLink = () => {
    handleSubLinks();
    resetSubLinks();
  };

  return (
    <div
      className={
        isMobile ? "container__subNavLink is--MOBILE" : "container__subNavLink"
      }
      onMouseLeave={handleLeave}
    >
      <ul className="subNavLinks--items">
        {isNewWorks && (
          <>
            <li
              className={"no--mobile item--nohover"}
              onMouseEnter={() => handleSubLinks()}
              onClick={() => handleNewLink()} // ! NEW
            >
              <Link to={`/works/new`}>
                <CustomFont
                  tag="p"
                  className={"small__font category--NAME"}
                  content={"NEW"}
                />
              </Link>
            </li>
            <li className="no--desktop item--nohover">
              <Link to={`/works/new`}>
                <CustomFont
                  tag="p"
                  className={"small__font category--NAME"}
                  content={"NEW"}
                />
              </Link>
            </li>
          </>
        )}
        {links
          .sort((a, b) => a.order - b.order)
          .map((link) => (
            <li
              key={uuidv4()}
              className={link.isActive ? "item active" : "item"}
              onMouseEnter={() => handleSubLinks(link)}
              onClick={() => handleSubLinks(link)}
            >
              <CustomFont
                className={"small__font t--CAPS"}
                content={link.name}
                tag={"p"}
              />
            </li>
          ))}
      </ul>
      {data.highlightLink !== null && data.highlightLink.work_categories && (
        <div className="container__ternary--links">
          <ul className="container__max-w">
            {data.highlightLink.work_categories
              .sort((a, b) => a.order - b.order)
              .map((category, i) => (
                <li className="item--CATEGORY" key={i}>
                  <div className="category--NAME">
                    <Link
                      to={`/works/category/${data.formatLink(category.name)}`}
                      onClick={resetSubLinks}
                    >
                      <CustomFont
                        tag="p"
                        className={"small__font external-LINK c--GREY"}
                        content={category.name}
                      />
                    </Link>
                  </div>
                  <ul className="container__series">
                    {data.workCategories
                      .find((el) => el.id === category.id)
                      .work_series.sort((a, b) => a.order - b.order) // UPDATE 12/04/2023
                      .map((serie, i) => (
                        <li className="serie--NAME" key={i}>
                          <Link
                            to={`/works/serie/${data.formatLink(serie.name)}`}
                            onClick={resetSubLinks}
                          >
                            <CustomFont
                              tag="p"
                              className={"small__font external-LINK c--GREY"}
                              content={serie.name}
                            />
                          </Link>
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SubNavLinksWorks;
